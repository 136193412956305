@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap');

* {
  font-family: 'Nunito', sans-serif !important;
}

.form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.formCreateUser {
  display: flex;
  flex-direction: column;
}

.passwordForm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.inputLabels {
  display: block;
  font-size: 1.5rem;
  margin-bottom: 30px;
}

.navBar {
  width: 70%;
  display: flex;
  justify-content: space-around;
}

.liNavLink {
  text-align: center;
  color: #101010;
  text-decoration: none;
  transition: 0.2s;
  font-size: 2.8rem;
  font-weight: 600 !important;
}

.liNavLink:hover {
  color: #80bb57;
  border-bottom: 4px solid #80bb57;
  font-weight: 800;
}

.Logo {
  display: flex;
  align-items: center;
  width: 315px;
  height: 100%;
}

.Home_Container .Home_ImageContainer {
  height: calc(100vh - 96px);
  background-image: url('./assets/images/Banner.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.imageHeader{
  max-width: 300px;
  max-height: 80px;
  margin-right: 5px;
  border-radius: 3px;
}

@media only screen and (max-width: 600px) {
  .Logo {
    width: 175px;
  }
  .Home_Container .Home_ImageContainer {
    height: calc(50vh - 80px);
    width: 100%;
    background-size: cover;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .Logo {
    width: 210px;
  }
}
